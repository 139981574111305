import { type ReactNode } from 'react';
import { useThirdPartySSODisabledUserAgentMatch } from '@hungryroot/environment';
import { Heading } from '@hungryroot/ramen';

import { ContinueWithOr } from '../ContinueWithOr/ContinueWithOr';
import { NewToHungryroot } from '../NewToHungryroot/NewToHungryroot';
import styles from './Login.module.css';

type Props = {
  logo: ReactNode;
  ssoButtons: ReactNode;
  loginForm: ReactNode;
  takeQuizLink: ReactNode;
  forgotPasswordLink: ReactNode;
};

export function Login({
  forgotPasswordLink,
  takeQuizLink,
  ssoButtons,
  logo,
  loginForm,
}: Props) {
  const isThirdPartySSODisabledUserAgentMatched =
    useThirdPartySSODisabledUserAgentMatch();

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <a href="/">{logo}</a>
      </header>
      <main className={styles.main}>
        <Heading variant="H1Alt">Welcome back</Heading>
        {!isThirdPartySSODisabledUserAgentMatched && (
          <ContinueWithOr>{ssoButtons}</ContinueWithOr>
        )}
        {loginForm}
        {forgotPasswordLink}
        <NewToHungryroot>{takeQuizLink}</NewToHungryroot>
      </main>
    </div>
  );
}
